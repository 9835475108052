
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "finance-outstanding-debit-notes",
  components: { Field },
  setup() {
    const debitNotes = ref([] as any);
    const publicIds = ref([""]);
    const displaySubmitButton = ref(false);

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    const formatDate = (dateString: string) => {
      return variables.formatDate(dateString);
    };

    const getOutstandingDebitNotes = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_DEDIT_NOTES_ROUTE)
          .then(({ data }) => {
            debitNotes.value = data?.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const publicIdArrays = (event, publicId: string) => {
      if (event.target.checked) {
        publicIds.value.push(publicId);
      } else {
        publicIds.value = publicIds.value.filter((id) => id !== publicId);
      }

      // if at least one item is checked, set the display submit button to true
      displaySubmitButton.value = publicIds.value.length > 1;
    };

    const processPayment = () => {
      return null;
    };

    onMounted(() => {
      getOutstandingDebitNotes();
      setCurrentPageBreadcrumbs("Outstanding", ["Finance", "Debit Notes"]);
    });

    return {
      addCommaToNumberString,
      formatDate,
      processPayment,
      publicIdArrays,
      debitNotes,
      displaySubmitButton,
    };
  },
  created() {
    //Set page title
    document.title =
      "Finance: Outstanding Debit Notes List | " + process.env.VUE_APP_TITLE;
  },
});
